import { Box, Button, Stack, Typography } from '@mui/material';
import { formatAddressMultiLine } from '@qb/frontend/utils/formatAddress';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
type NewCustomerAddedProps = {
  existingContactInfo: NonNullable<ContactFindOneByEmailResponse['existingContactInfo']>;
  onClose: () => void;
};
export const NewCustomerAdded = ({
  existingContactInfo: {
    contact,
    address,
    phone
  },
  onClose
}: NewCustomerAddedProps) => {
  return <Stack sx={{
    p: 3,
    maxWidth: 400,
    mx: 'auto',
    py: 4,
    justifyContent: 'center',
    minHeight: '100%'
  }} data-sentry-element="Stack" data-sentry-component="NewCustomerAdded" data-sentry-source-file="NewCustomerAdded.tsx">
      <Typography variant="h4" sx={{
      mb: 4
    }} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
        New customer was added!
      </Typography>
      <Box data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Typography variant="h6" sx={{
        mb: 1
      }} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Customer Details:
        </Typography>
        <Typography variant="paragraph" sx={{
        color: 'text.primary',
        mb: 3
      }} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Name: {`${contact.firstName} ${contact.lastName}`}
          Email: {contact.email}
          {!!phone && <>
              <br />
              Contact Number: {phone}
            </>}
        </Typography>
      </Box>
      <Box data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Typography variant="h6" sx={{
        mb: 1
      }} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          Company Details:
        </Typography>
        <Typography variant="paragraph" sx={{
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="NewCustomerAdded.tsx">
          {contact.companyName && contact.companyName}
          <br />
          {!!address && formatAddressMultiLine(address)}
          <br />
          {!!address?.country && address.country}
        </Typography>
      </Box>
      <Box sx={{
      mt: 3
    }} data-sentry-element="Box" data-sentry-source-file="NewCustomerAdded.tsx">
        <Button onClick={onClose} color="primary" variant="outlined" fullWidth data-sentry-element="Button" data-sentry-source-file="NewCustomerAdded.tsx">
          OK
        </Button>
      </Box>
    </Stack>;
};
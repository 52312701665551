module.exports = {
  QB_COMPANY_ID: 1,
  QB_TIMEZONE: 'America/Los_Angeles',
  DEFAULT_PAGE_SIZE: 10,

  // TODO: combine with each models' MODEL_NAME variable
  ModelNames: {
    PendingUser: 'Pending User',
    Contact: 'Contact',
    User: 'User',
    Company: 'Company',
  },

  // Equivalent to a model's identity (lowercase only).
  ResourceNames: {
    Company: 'company',
    Contact: 'contact',
    Conversation: 'conversation',
    ConversationReadStatus: 'conversationreadstatus',
    ConversationMembership: 'conversationmembership',
    Message: 'message',
    PendingUser: 'pendinguser',
    User: 'user',
    Quote: 'quote',
    Order: 'order',
  },

  Message: {
    MESSAGE_TYPE_TEXT: 'Text',
    MESSAGE_TYPE_PART: 'Part',
    MESSAGE_TYPE_QUOTE: 'Quote',
    MESSAGE_TYPE_FILE: 'File',
    MESSAGE_TYPE_PHOTO: 'Photo',
  },

  Errors: {
    FILE_SIZE_EXCEEDED: 'FILE_SIZE_EXCEEDED',
    EXISTING_CONVERSATION_FOR_PART_ORDER:
      'EXISTING_CONVERSATION_FOR_PART_ORDER',
    EXISTING_CONVERSATION_FOR_QUOTE: 'EXISTING_CONVERSATION_FOR_QUOTE',
  },

  IntegrationStatuses: {
    INTEGRATION_TRUE: 'true',
    INTEGRATION_FALSE: 'false',
    INTEGRATION_MANUAL: 'manual',
    INTEGRATION_EXCEPTION: 'exception',
    INTEGRATION_PROCESSING: 'processing',
  },

  IntegrationLogs: {
    CODE_BILLING_CONTACT: {
      code: 'BILLING_CONTACT',
      title: 'Contact mismatch',
    },
    CODE_CREDIT_HOLD: { code: 'CREDIT_HOLD', title: 'Account on Credit Hold' },
    CODE_CUSTOMER_ID: { code: 'CUSTOMER_ID', title: 'Customer ID' },
    CODE_INTEGRATION_EXCEPTION: {
      code: 'INTEGRATION_EXCEPTION',
      title: 'Integration exception',
    },
    CODE_INTERNAL_EXCEPTION: {
      code: 'INTERNAL_EXCEPTION',
      title: 'Internal exception',
    },
    CODE_ORDER_ITEM_MISSING: {
      code: 'ORDER_ITEM_MISSING',
      title: 'Order item missing',
    },
    CODE_PART_NUMBER: { code: 'PART_NUMBER', title: 'No Part Number Found' },
    CODE_PROFIT_MARGIN: { code: 'PROFIT_MARGIN', title: 'Margin is too low' },
    CODE_UNIT_OF_MEASURE: {
      code: 'UNIT_OF_MEASURE',
      title: 'No part unit of measure found',
    },
    CODE_SHIPPING_METHOD: {
      code: 'SHIPPING_METHOD',
      title: 'Shipping method not found',
    },
    CODE_SITE_ID: { code: 'SITE_ID', title: 'Site ID' }, // SHIPPING ADDRESS
    CODE_TERMS: { code: 'TERMS', title: 'Payment Terms' },
    CODE_UNKNOWN: { code: 'UNKNOWN', title: 'Unknown' },
  },

  Widget: {
    MessageNames: {
      // Application to Widget
      OPEN_FOR_APP_ID: 'OPEN_FOR_APP_ID',
      OPEN_FOR_COMPANY_VIA_APP_ID: 'OPEN_FOR_COMPANY_VIA_APP_ID',
      COLLAPSE_WIDGET: 'COLLAPSE_WIDGET',
      SHOW_WIDGET: 'SHOW_WIDGET',
      HIDE_WIDGET: 'HIDE_WIDGET',
      CREATE_CONVERSATION_FOR_AUTHENTICATED_USER:
        'CREATE_CONVERSATION_FOR_AUTHENTICATED_USER',
      CREATE_CONVERSATION_FOR_UNAUTHENTICATED_USER:
        'CREATE_CONVERSATION_FOR_UNAUTHENTICATED_USER',
      RESET_WIDGET: 'RESET_WIDGET',

      // Widget to Application
      READY: 'READY',
      USER_SIGNUP: 'USER_SIGNUP',
      USER_LOGIN: 'USER_LOGIN',
    },
    QB_NAMESPACE: 'QB',
    QB_APP_ID: 1,
    POWERMATIC_APP_ID: 2,
    VERTICAL_STYLE_NAVBAR_WIDTH: 80,
    VERTICAL_STYLE_NAVBAR_HEIGHT: 60,
    HORIZONTAL_STYLE_NAVBAR_DESKTOP_HEIGHT: 71,
    HORIZONTAL_STYLE_NAVBAR_MOBILE_HEIGHT: 60,
  },

  Headers: {
    ORIGINAL_STATUS: 'Original-Status',
  },

  BOT_STRING: 'bot',

  MAX_PUBLIC_IMAGE_SIZE: 500 * 1024,
  MAX_IMAGE_SIZE: 5 * 1024 * 1024,
  MAX_FILE_SIZE: 20 * 1024 * 1024,
  MIMETYPE_PDF: 'application/pdf',
  MAX_STRING_LENGTH: 255,
  MAX_PHONE_LENGTH: 25,

  REALISTIC_MAX_SHIP_PROCESSING_DAYS: 5,
};

import { Controller, useFormContext } from 'react-hook-form';
import { Alert, AlertTitle, Box, Card, FormControlLabel, Radio, RadioGroup, Stack, Typography, TextField, MenuItem, FormControl } from '@mui/material';
import { formatAddressMultiLine } from '@qb/frontend/utils/formatAddress';
import Constants from '@/shared/Constants';
import STATES from '@/shared/States';
import { COUNTRIES_LIST } from '@/shared/countriesList';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
import { CustomerEnrichmentLine } from '@/src/components/CustomerEnrichment/components/CustomerEnrichmentLine';
import { FormData } from './AddCustomerAndCompanyForm';
import { FormField } from './FormField';
const maxLength = {
  value: Constants.MAX_STRING_LENGTH,
  message: `This field must be less than ${Constants.MAX_STRING_LENGTH} characters.`
};
type CompanyDetailsFormProps = {
  sameDomainCompanies?: ContactFindOneByEmailResponse['sameDomainCompanies'];
  enrichment?: ContactFindOneByEmailResponse['enrichment'];
};
export const CompanyDetailsForm = ({
  sameDomainCompanies,
  enrichment
}: CompanyDetailsFormProps) => {
  const {
    control,
    watch
  } = useFormContext<FormData>();
  const customerID = watch('customerID');
  const email = watch('email');
  const emailDomain = email.split('@')[1];
  const isUSACountrySelected = (watch('companyCountryCode') || 'US') === 'US';
  const companyFields = <>
      <FormField name="companyName" label="Company Name" enriched={Boolean(enrichment?.companyName)} />
      <FormField name="companyStreet" label="Address line 1 (optional)" />
      <FormField name="companyUnit" label="Address line 2 (optional)" />
      <Stack direction="row" spacing={2} sx={{
      mb: 2,
      mt: 2
    }}>
        <FormField name="companyCity" label="City (optional)" />
        <FormControl fullWidth>
          <Controller name="companyState" control={control} rules={{
          required: 'This field is required.',
          maxLength
        }} render={({
          field
        }) => <TextField {...field} select={isUSACountrySelected} label="State/Province (optional)">
                {isUSACountrySelected && STATES.states.map(({
            value,
            name
          }) => <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>)}
              </TextField>} />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormField name="companyZipCode" label="ZIP code (optional)" />
        <FormControl fullWidth>
          <Controller name="companyCountryCode" control={control} rules={{
          required: 'This field is required.'
        }} render={({
          field
        }) => <TextField {...field} select label="Country (optional)">
                {Object.entries(COUNTRIES_LIST).map(([code, name]) => <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>)}
              </TextField>} />
        </FormControl>
      </Stack>
      <FormField name="companyPhone" label="Company Phone (optional)" />
    </>;
  return <Box sx={{
    width: '100%'
  }} data-sentry-element="Box" data-sentry-component="CompanyDetailsForm" data-sentry-source-file="CompanyDetailsForm.tsx">
      <Box sx={{
      height: 70
    }} data-sentry-element="Box" data-sentry-source-file="CompanyDetailsForm.tsx">
        <Typography variant="h4" sx={{
        pt: 2
      }} data-sentry-element="Typography" data-sentry-source-file="CompanyDetailsForm.tsx">
          Company Details
        </Typography>
      </Box>
      {sameDomainCompanies && sameDomainCompanies.length > 0 ? <>
          <Alert severity="info" sx={{
        mb: 2,
        mt: 2
      }}>
            <AlertTitle>
              This user domain @{emailDomain} already exists under an existing
              company.
            </AlertTitle>
            <Typography variant="bodyMedium">
              We recommend adding a new user to an existing company in order to
              apply all discounts associated with the company and track quotes
              and orders.
            </Typography>
          </Alert>
          <Controller name="customerID" control={control} render={({
        field
      }) => <RadioGroup {...field} sx={{
        width: '100%',
        label: {
          width: '100%'
        },
        '.MuiTypography-root': {
          width: '100%'
        }
      }}>
                {sameDomainCompanies.map(company => <Card key={company.id} sx={{
          p: 3,
          width: '100%',
          mb: 2
        }}>
                    <FormControlLabel value={company.id} control={<Radio />} label={<Box sx={{
            width: '100%'
          }}>
                          <Typography variant="h6" sx={{
              color: 'text.secondary'
            }}>
                            {company.name}
                          </Typography>
                          {company.primaryAddress && <Box sx={theme => ({
              span: theme.typography.bodySmall
            })}>
                              {formatAddressMultiLine(company.primaryAddress)}
                            </Box>}
                          {isNonEmptyArray(company.users) && <>
                              <Typography variant="h6" sx={{
                color: 'text.secondary',
                mt: 3,
                mb: 1
              }}>
                                Users
                              </Typography>
                              {company.users.map(user => <Stack direction="row" key={user.id} sx={{
                justifyContent: 'space-between'
              }}>
                                  <Box>
                                    <Typography variant="bodyMedium">
                                      {user.firstName} {user.lastName}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <CustomerEnrichmentLine typographySx={{
                    typography: 'bodyMedium'
                  }} text={`Email: ${user.email}`} source="user" />
                                    <CustomerEnrichmentLine typographySx={{
                    typography: 'bodyMedium'
                  }} text={user.phone && `Phone number: ${user.phone}`} source={user.phoneSource} />
                                  </Box>
                                </Stack>)}
                            </>}
                        </Box>} />
                  </Card>)}
                <Card sx={{
          p: 3
        }}>
                  <FormControlLabel value="new" control={<Radio />} label="Create new company" />
                  {customerID === 'new' && companyFields}
                </Card>
              </RadioGroup>} />
        </> : companyFields}
    </Box>;
};
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Grid2 } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { AddCustomerAndCompanyForm } from '@/src/components/CreateNewCustomerDrawer/AddCustomerAndCompanyForm';
import { ContactEmailInput } from '@/src/components/CreateNewCustomerDrawer/ContactEmailInput';
import { NewCustomerAdded } from '@/src/components/CreateNewCustomerDrawer/NewCustomerAdded';
import { CreateNewQuoteDrawer } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { EMAIL_REGEX } from '@/src/utils/Constants';
import { StandardDrawerLayout } from '../Common/StandardDrawerLayout/StandardDrawerLayout';
type Step = 'contactEmail' | 'contactAndCompanyForm' | 'contactCreated';
type CreateNewCustomerDrawerProps = {
  returnToCreateQuote?: boolean;
  initialEmail?: string;
};
type ContactCreated = NonNullable<ContactFindOneByEmailResponse['existingContactInfo']>;
export const CreateNewCustomerDrawer = ({
  returnToCreateQuote,
  initialEmail
}: CreateNewCustomerDrawerProps) => {
  const tenantAlias = useTenantAlias();
  const {
    hideDrawer,
    setActiveDrawer
  } = useRightDrawerContext();
  const queryClient = useQueryClient();
  const [step, setStep] = useState<Step>('contactEmail');
  const [contactCreated, setContactCreated] = useState<ContactCreated | null>(null);
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery(initialEmail);
  const isQueryValidEmail = debouncedQuery.length > 0 && isValidEmail(debouncedQuery);
  const findOneByEmailQuery = spaQueries.contact.findOneByEmail(tenantAlias, {
    email: debouncedQuery
  });
  const {
    data: existingContactData,
    isFetching,
    refetch
  } = useQuery({
    ...findOneByEmailQuery,
    enabled: isQueryValidEmail
  });
  const createCustomerUserMutation = useMutation({
    ...spaQueries.user.createCustomerUser(tenantAlias),
    onSuccess: async () => {
      const {
        data: updatedContact
      } = await refetch();
      if (updatedContact?.existingContactInfo?.contact) {
        // If we are returning to create a quote, we want to skip the confirmation screen
        if (returnToCreateQuote) {
          return setActiveDrawer(<CreateNewQuoteDrawer initialSelectedContact={updatedContact?.existingContactInfo.contact} />);
        }
        setContactCreated(updatedContact?.existingContactInfo);
        setStep('contactCreated');
      }
      await queryClient.invalidateQueries(spaQueries.contact.listContacts(tenantAlias).queryKey.filter(Boolean));
      await queryClient.invalidateQueries(findOneByEmailQuery);
    },
    onError: () => {
      toast.error('There was an error creating the contact');
    }
  });
  const onClose = () => {
    if (returnToCreateQuote) {
      setActiveDrawer(<CreateNewQuoteDrawer initialSelectedContact={contactCreated?.contact} />);
      return;
    }
    hideDrawer();
  };
  if (contactCreated && step === 'contactCreated') {
    return <NewCustomerAdded existingContactInfo={contactCreated} onClose={onClose} />;
  }
  return <StandardDrawerLayout title="Add New Customer" data-sentry-element="StandardDrawerLayout" data-sentry-component="CreateNewCustomerDrawer" data-sentry-source-file="CreateNewCustomerDrawer.tsx">
      <Grid2 container spacing={4} sx={{
      pb: 5,
      maxWidth: 1200
    }} data-sentry-element="Grid2" data-sentry-source-file="CreateNewCustomerDrawer.tsx">
        {step === 'contactEmail' ? <ContactEmailInput onContinue={() => setStep('contactAndCompanyForm')} onCancel={onClose} existingContactInfo={existingContactData?.existingContactInfo} isFetching={isFetching} query={query} setQuery={setQuery} isQueryValidEmail={isQueryValidEmail} /> : <AddCustomerAndCompanyForm onSubmit={submitData => {
        const {
          customerID,
          companyStreet,
          companyUnit,
          companyCity,
          companyState,
          companyZipCode,
          companyCountryCode,
          companyPhone,
          ...userData
        } = submitData;
        if (customerID === 'new') {
          createCustomerUserMutation.mutate({
            ...userData,
            companyStreet,
            companyUnit,
            companyCity,
            companyState,
            companyZipCode,
            companyCountryCode,
            companyPhone
          });
        } else {
          // copy address information from the existing company.
          const existingCompany = (existingContactData?.sameDomainCompanies || []).find(({
            id
          }) => id === Number(customerID));
          createCustomerUserMutation.mutate({
            ...userData,
            customerID,
            companyStreet: existingCompany?.primaryAddress?.street,
            companyUnit: existingCompany?.primaryAddress?.street2,
            companyCity: existingCompany?.primaryAddress?.city,
            companyState: existingCompany?.primaryAddress?.state,
            companyZipCode: existingCompany?.primaryAddress?.zipCode,
            companyCountryCode: existingCompany?.primaryAddress?.countryCode
          });
        }
      }} onCancel={onClose} sameDomainCompanies={existingContactData?.sameDomainCompanies} enrichment={existingContactData?.enrichment} email={debouncedQuery} isLoading={createCustomerUserMutation.isLoading} />}
      </Grid2>
    </StandardDrawerLayout>;
};
const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};